import React, { useState } from "react";
import { Button, Input, Modal } from "semantic-ui-react";
import MaskedInput from "react-text-mask";

export default function InviteModal({ open, onCancel, onSubmit }) {
  const [nickname, setNickname] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  return (
    <div>
      <Modal open={open} onClose={onCancel}>
        <Modal.Header>Join the fun!</Modal.Header>
        <Modal.Content>
          <p>
            Enter your name and phone number. You will then receive a text with instruction on how to add your photos to the album.
          </p>
          <Input
            autoFocus
            margin="dense"
            id="nickname"
            label="Name"
            type="text"
            onChange={e => {
              setNickname(e.target.value);
            }}
            fluid
          />
          <Input
            required
            fluid
            style={{ marginTop: "8px" }}
            name="phone"
            label="Phone Number"
            input={
              <MaskedInput
                mask={[
                  "+",
                  "1",
                  " ",
                  /\d/,
                  /\d/,
                  /\d/,
                  "-",
                  /\d/,
                  /\d/,
                  /\d/,
                  "-",
                  /\d/,
                  /\d/,
                  /\d/,
                  /\d/
                ]}
                type="tel"
                pattern="\+1 \d{3}-[\d]{3}-[\d]{4}"
                guide={false}
                placeholder="555-555-5555"
              />
            }
            value={phoneNumber}
            onChange={e => setPhoneNumber(e.target.value)}
          />
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={onCancel}>Cancel</Button>
          <Button
            onClick={() => {
              onSubmit({ phoneNumber: phoneNumber.split(/[ -]/).join(""), nickname });
            }}
          >
            Join
          </Button>
        </Modal.Actions>
      </Modal>
    </div>
  );
}
