import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { formatDistance } from "date-fns";
import {
  Input,
  Button,
  Container,
  Card,
  Loader,
  Grid
} from "semantic-ui-react";
import { withUser, withAuthorization } from "../Session";
import Firebase, { withFirebase } from "../Firebase";
import { compose } from "recompose";
import { ALBUM } from "../../constants/routes";
import NewAlbumModal from "./NewAlbumModal";
import { User } from "../Firebase/firebase";

interface IProps {
  firebase: Firebase,
  user: User,
}

function HomePage({ firebase, user }) {
  const [loading, setLoading] = useState(true);
  const [albums, setAlbums] = useState([]);
  const [shouldShowDialog, setShouldShowDialog] = useState(false);
  const [shouldShowSuccess, setShouldShowSuccess] = useState(false);
  useEffect(() => {
    firebase.onAlbums(user.uid, snapshot => {
      const albumDocs = [];
      for (let doc of snapshot.docs) {
        albumDocs.push(doc.data().album);
      }
      return Promise.all(albumDocs.map(doc => doc.get())).then(albumDocs => {
        setLoading(false);
        setAlbums(
          albumDocs
            .map(albumSnapshot => albumSnapshot.data())
            .sort((a, b) => b.createdAt.seconds - a.createdAt.seconds)
        );
      });
    });
  }, []);
  return (
    <Container>
      <h1>Your albums</h1>
      <Button onClick={() => setShouldShowDialog(true)} primary>
        Create Album
      </Button>
      <NewAlbumModal
        open={shouldShowDialog}
        onCancel={() => setShouldShowDialog(false)}
        onSubmit={title =>
          firebase
            .createAlbum(user.uid, title)
            .then(() => setShouldShowDialog(false))
            .then(() => setShouldShowSuccess(true))
        }
      />
      {albums.length > 0 ? (
        albums.map(album => (
          <Card
            link
            as={Link}
            to={ALBUM(album.shortId)}
            fluid
            header={album.title}
            meta={`Created
                        ${formatDistance(album.createdAt.toDate(), new Date())}
                        ago`}
            key={album.shortId}
          />
        ))
      ) : (
          <Loader active />
        )}
      {/* <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        open={shouldShowSuccess}
        autoHideDuration={6000}
        onClose={() => {
          setShouldShowSuccess(false);
        }}
        ContentProps={{
          "aria-describedby": "message-id"
        }}
        message={<span id="message-id">Album created!</span>}
        action={[
          <IconButton
            key="close"
            aria-label="close"
            color="inherit"
            className={classes.close}
            onClick={() => {
              setShouldShowSuccess(false);
            }}
          >
            <CloseIcon />
          </IconButton>
        ]}
      /> */}
    </Container>
  );
}

const Home = compose<IProps, {}>(
  withAuthorization(user => !!user),
  withUser,
  withFirebase
)(HomePage);

export default Home;
