import firebase from 'firebase/app';
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import ShortId from "shortid";

const config = {
  apiKey: "AIzaSyBAB6puCZyx-6o64M8vG9JEspn0PWoRtCg",
  authDomain: "jointly-f4adc.firebaseapp.com",
  databaseURL: "https://jointly-f4adc.firebaseio.com",
  projectId: "jointly-f4adc",
  storageBucket: "jointly-f4adc.appspot.com",
  messagingSenderId: "555847503658",
  appId: "1:555847503658:web:f3f34cd58fd3a35e"
};

export interface DBUser {
  name: string,
  phoneNumber: string,
}

export interface User extends DBUser {
  uid: string,
};

export interface Photo {
  url: string,
  createdAt: firebase.firestore.Timestamp,
  shortId: string,
  uploader: string,
  thumbnailUrl?: string
}

class Firebase {
  public app: firebase.app.App;
  public auth: firebase.auth.Auth;
  public db: firebase.firestore.Firestore;
  public storage: firebase.storage.Storage;
  public emailAuthProvider: firebase.auth.EmailAuthProvider;

  constructor() {
    this.app = firebase.initializeApp(config);


    /* Firebase APIs */

    this.auth = this.app.auth();
    this.db = this.app.firestore();
    this.storage = this.app.storage();

  }

  signUpWithPhone = (phoneNumber, captchaVerifier) =>
    this.auth.signInWithPhoneNumber(phoneNumber, captchaVerifier);

  captchaVerifier = id =>
    new firebase.auth.RecaptchaVerifier(id, {
      size: "invisible"
    });

  onAuthUserListener = (next, fallback) =>
    this.auth.onAuthStateChanged(authUser => {
      if (authUser) {
        this.user(authUser.uid)
          .get()
          .then(snapshot => {
            const dbUser = <DBUser>snapshot.data();

            // merge auth and db user
            const fullUser: User = {
              uid: authUser.uid,
              ...dbUser
            };

            next(fullUser);
          });
      } else {
        fallback();
      }
    });

  user = uid => this.db.doc(`users/${uid}`);

  users = () => this.db.collection("users");

  getUserByPhone = number => {
    return this.users()
      .where("phoneNumber", "==", number)
      .get()
      .then(userQuery => {
        if (userQuery.empty) {
          return null;
        }
        return userQuery.docs[0];
      });
  };

  doSignOut = () => this.auth.signOut();

  albums = uid =>
    this.db.collection("memberships").where("user", "==", this.user(uid));

  getAlbums = uid => this.albums(uid).get();

  onAlbums = (uid, callback) => this.albums(uid).onSnapshot(callback);

  album = shortId =>
    this.db.collection("albums").where("shortId", "==", shortId);

  getAlbum = shortId => this.album(shortId).get();

  createShortId = () => ShortId.generate();

  addPhoto = async (albumRef: firebase.firestore.DocumentReference, url: string, phoneNumber: string, id: string) => {
    return albumRef.collection("images").add({
      url,
      uploader: phoneNumber,
      createdAt: firebase.firestore.Timestamp.fromMillis(Date.now()),
      shortId: id || ShortId.generate()
    });
  };

  getPhoto = async (albumId, photoId) => {
    const albumQuery = await this.getAlbum(albumId);
    const albumRef = albumQuery.docs[0].ref;
    const photo = await albumRef
      .collection("images")
      .where("shortId", "==", photoId)
      .get();
    return photo.docs[0];
  };

  addThumbnailUrls = async (imageDoc: firebase.firestore.DocumentSnapshot): Promise<Photo> => {
    try {
      const storageRef = this.storage.refFromURL(imageDoc.data().url);
      const thumbPath = storageRef.parent.fullPath + '/thumb@' + storageRef.name;
      const thumbnailUrl = await this.storage.ref(thumbPath).getDownloadURL();

      return {
        thumbnailUrl,
        ...(imageDoc.data() as Photo)
      }
    } catch {
      return imageDoc.data() as Photo;
    }
  }

  getMembership = async (uid, albumRef) =>
    this.db
      .collection("memberships")
      .where("user", "==", this.db.collection("users").doc(uid))
      .where("album", "==", albumRef)
      .get();

  createAlbum = async (uid: string, title: string): Promise<firebase.firestore.DocumentReference> => {
    const newAlbum = await this.db.collection("albums").add({
      title,
      createdAt: firebase.firestore.Timestamp.fromMillis(Date.now()),
      shortId: ShortId.generate(),
      owner: this.db.collection("users").doc(uid)
    });
    return this.db.collection("memberships").add({
      album: newAlbum,
      user: this.user(uid),
      owner: true
    });
  };

  createInvite = (albumRef, phoneNumber, nickname) =>
    this.db
      .collection("invites")
      .add({ album: albumRef, recipient: { nickname, number: phoneNumber } });
}

export default Firebase;
