import React, { useState, useEffect, useRef, ChangeEvent } from "react";
import {
  Button,
  Grid,
  Container,
  Divider,
  Breadcrumb,
  Segment,
  Image,
  Icon,
  Loader,
  Progress,
  Menu,
  Message
} from "semantic-ui-react";
import { withUser } from "../Session";
import Firebase, { withFirebase } from "../Firebase";
import { compose } from "recompose";
import ImageCard from "./ImageCard";
import InviteModal from "./InviteModal";
import * as ROUTES from "../../constants/routes";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import { User } from "firebase";
import InfiniteScroll from './InfiniteScroll';
import WelcomeModal from "./HBWedding/WelcomeModal";

interface MatchParams {
  shortId: string;
}


interface IProps extends RouteComponentProps<MatchParams> {
  firebase: Firebase,
  user: User,
}

enum Filter {
  All,
  Mine,
}

function AlbumPage({ firebase, match, user, history }: IProps) {
  const [album, setAlbum] = useState(null);
  const [imageDocs, setImageDocs] = useState([]);
  const [shouldShowInviteModal, setShouldShowInviteModal] = useState(false);
  const [filter, setFilter] = useState(Filter.All);
  const oldestDoc = useRef(null);
  const newestDoc = useRef(null);
  const [endOfAlbum, setEndOfAlbum] = useState(false);
  const [hasDismissedGreeting, setHasDismissedGreeting] = useState(false);
  const [hasTextSent, setHasTextSent] = useState(false);

  const imageDocsRef = useRef(imageDocs);

  useEffect(() => {
    imageDocsRef.current = imageDocs;
  }, [imageDocs])

  useEffect(() => {
    firebase.getAlbum(match.params.shortId).then(collection => {
      if (collection.empty) {
        return;
      }
      const album = collection.docs[0];
      setAlbum(album);
    });
  }, []);

  function loadPage() {
    let imagesQuery: firebase.firestore.Query = album.ref.collection("images");
    let query = imagesQuery.orderBy("createdAt", "desc").limit(12);
    const lastDoc = oldestDoc.current;
    if (oldestDoc.current) {
      //use the most recently loaded doc as pivot point for next page of photos
      query = query.startAfter(lastDoc);
    }
    return query.get().then(imgCollection => {
      const fullImageData = imgCollection.docs.map(firebase.addThumbnailUrls);
      Promise.all(fullImageData).then(data => {
        console.log("setting images from infinite scroll");
        setImageDocs([...imageDocsRef.current, ...data]);
      });
      if (!imgCollection.empty) {
        const endOfList = imgCollection.docs[imgCollection.docs.length - 1];
        oldestDoc.current = endOfList;
        if (!newestDoc.current) {
          //ininitialize subscription to newer documents
          const latestDoc = imgCollection.docs[0];
          (album.ref.collection("images") as firebase.firestore.Query).orderBy("createdAt", "asc").startAfter(latestDoc).onSnapshot(imgCollection => {
            const fullImageData = imgCollection.docs.map(firebase.addThumbnailUrls);
            Promise.all(fullImageData).then(data => {
              console.log("setting images from snapshot");
              setImageDocs([...data.filter(img => !imageDocsRef.current.some(existingImg => existingImg.shortId == img.shortId)).reverse(), ...imageDocsRef.current]);
            });
            newestDoc.current = latestDoc;
          })
        }
      } else {
        setEndOfAlbum(true);
      }
    });
  }

  return (
    <Container>
      <Breadcrumb>
        <Breadcrumb.Section>
          <Link to={ROUTES.HOME}>Home</Link>
        </Breadcrumb.Section>
        <Breadcrumb.Divider>></Breadcrumb.Divider>
        <Breadcrumb.Section active>
          {album ? album.data().title : <Icon loading name="asterisk" />}
        </Breadcrumb.Section>
      </Breadcrumb>

      <WelcomeModal onDone={() => { setHasDismissedGreeting(true) }} open={!user && !hasDismissedGreeting && match.params.shortId === "wQEUeS2E5"} />

      <h1>{album && album.data().title}</h1>
      <Button
        content="Share Album"
        labelPosition="left"
        icon="share square"
        onClick={() => {
          shareAlbum(album.data().title);
        }}
      />

      <Divider section />
      <PhotoUploader history={history} user={user} album={album} firebase={firebase} onTextOptionClick={() => { if (!user) { setShouldShowInviteModal(true) } else { setHasTextSent(true) } }} />
      <Message positive hidden={!hasTextSent} onDismiss={() => { setHasTextSent(false) }} header="Text sent!" content="You should receive a text shortly with instructions." />

      <Menu pointing secondary fluid widths={1}>
        <Menu.Item
          name='All'
          active={filter == Filter.All}
          onClick={() => { setFilter(Filter.All); }}
        />
        {/* <Menu.Item
          name='Uploaded by me'
          active={filter == Filter.Mine}
          onClick={() => { setFilter(Filter.Mine); }}
        /> */}
      </Menu>
      <Grid style={{ marginTop: '14' }} columns={3}>
        {album && <InfiniteScroll
          loadMore={loadPage}
          loadingView={<Loader active />}
          errorView={null}
          isDone={endOfAlbum}>
          {
            imageDocs.map(imageData => (
              <Grid.Column style={{ padding: 1 }} key={imageData.shortId}>
                <ImageCard href={`/album/${match.params.shortId}/photo/${imageData.shortId}`} src={imageData.thumbnailUrl || imageData.url} />
              </Grid.Column>
            ))
          }
        </InfiniteScroll>}
      </Grid>

      <InviteModal
        open={shouldShowInviteModal}
        onCancel={() => {
          setShouldShowInviteModal(false);
        }}
        onSubmit={({ nickname, phoneNumber }) => {
          const requests = [];
          if (!user) {
            requests.push(firebase.auth.signInAnonymously().then(({ user }) => {
              const dbUserRef = firebase.user(user.uid);
              const userData = dbUserRef.set({
                name: nickname,
                phoneNumber,
              });
              const membership = firebase.db.collection('memberships').add({
                user: dbUserRef,
                album: album.ref,
              })
              Promise.all([userData, membership]);
            }));
          }
          requests.push(firebase.createInvite(album.ref, phoneNumber, nickname));
          Promise.all(requests).then(() => { setShouldShowInviteModal(false); setHasTextSent(true) });
        }}
      />
    </Container>
  );
}

function shareAlbum(albumName) {
  const albumUrl = window.location.href;
  let navigator: any;
  navigator = window.navigator;
  if (navigator.share) {
    navigator.share({
      title: `${albumName} | A Jointly Album`,
      url: albumUrl
    });
  } else {
    navigator.clipboard.writeText(albumUrl);
  }
}

function PhotoUploader({ firebase, album, user, history, onTextOptionClick }) {
  const fileInputRef = useRef<HTMLInputElement>();
  const [uploads, setUploads] = useState([]);
  const fileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const storageRef = firebase.storage.ref('/photos');
    const files: Array<File> = Array.from(e.target.files);
    setUploads(
      files.map(file => {
        const shortId = firebase.createShortId();
        const extension = file.name.split(".")[1];
        const task = storageRef.child(shortId + '/' + shortId + "." + extension).put(file);
        task.then(snapshot => {
          snapshot.ref.getDownloadURL().then(downloadURL => {
            firebase.addPhoto(album.ref, downloadURL, user.phoneNumber, shortId).then(() => {
              setUploads(uploads.filter(upload => upload.task !== task));
            })
          });
        });
        return {
          file,
          task
        };
      })
    );
  };

  return (
    <div>
      <Button.Group fluid size="large">
        <Button
          content="Upload"
          labelPosition="left"
          icon="cloud upload"
          onClick={() => {
            if (!user) {
              if (window.confirm("To upload photos you must sign in first.")) {
                //return history.push({ pathname: ROUTES.SIGN_UP, state: { afterSignIn: this.props.history.location.pathname } });
                return onTextOptionClick();
              } else {
                return;
              }
            }
            fileInputRef!.current && fileInputRef!.current!.click();
          }}
          primary
        />
        <Button.Or />
        <Button content="Send via Text" positive onClick={() => onTextOptionClick()}></Button>
      </Button.Group>
      <input
        multiple={true}
        ref={fileInputRef}
        accept="image/png, image/jpeg, image/jpg"
        type="file"
        hidden
        onChange={fileChange}
      />
      {uploads.map(({ file, task }) => (
        <UploadProgress key={file.name} file={file} uploadTask={task} />
      ))}
    </div>
  );
}

function UploadProgress({ file, uploadTask }) {
  const [previewImage, setPreviewImage] = useState(null);
  const [progress, setProgress] = useState({ current: 0, total: 100 });
  useEffect(() => {
    const fileReader = new FileReader();
    fileReader.onloadend = () => {
      setPreviewImage(fileReader.result);
    };
    fileReader.readAsDataURL(file);
    const unListen = uploadTask.on(
      "state_changed",
      snapshot => {
        // Observe state change events such as progress, pause, and resume
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        setProgress({ current: snapshot.bytesTransferred, total: snapshot.totalBytes })
      },
      () => { },
      () => { }
    );
    return unListen;
  }, []);

  return (
    <Segment style={{ display: "flex", alignItems: "center" }}>
      <Image
        src={previewImage}
        rounded
        style={{ width: "42px", height: "42px", marginRight: "14px" }}
      />
      <Progress
        style={{ margin: "0", flexGrow: 2 }}
        size="small"
        value={progress.current}
        total={progress.total}
        precision={0}
        progress="percent"
        indicating
      >Uploading...</Progress>
    </Segment>
  );
}

export default compose<IProps, {}>(withUser, withFirebase, withRouter)(AlbumPage);
