import React, { useEffect, useState } from "react";
import Firebase, { withFirebase } from "./Firebase";
import * as ROUTES from "../constants/routes";
import { Link, RouteComponentProps } from "react-router-dom";
import {
  Container,
  Image,
  Breadcrumb,
  Icon,
  Segment,
  Label,
  Placeholder,
  Button,
  Confirm
} from "semantic-ui-react";

import { css } from 'emotion';

import { compose } from "recompose";
import { withUser } from "./Session";
import { User } from "firebase";

interface MatchParams {
  photoId: string;
  albumId: string;
}


interface IProps extends RouteComponentProps<MatchParams> {
  firebase: Firebase,
  user: User,
}

const topBanner = css`
  display: flex;
  align-items: center;
`;

const actions = css`
  display: flex;
  flex-grow: 2;
  justify-content: flex-end;
`;

function PhotoViewer({ match, firebase, history, user }: IProps) {
  const { photoId, albumId } = match.params;
  const [album, setAlbum] = useState(null);
  const [photo, setPhoto] = useState(null);
  const [photoRef, setPhotoRef] = useState(null);
  const [uploader, setUploader] = useState(null);
  const [neighbors, setNeighbors] = useState({ left: null, right: null });
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  async function getNeightbors(photoDoc: firebase.firestore.QueryDocumentSnapshot) {
    const collection = photoDoc.ref.parent;
    const baseQuery = collection.limit(1);
    const before = baseQuery.orderBy('createdAt', 'asc').startAfter(photoDoc);
    const after = baseQuery.orderBy('createdAt', 'desc').startAfter(photoDoc);
    const [beforeQuery, afterQuery] = await Promise.all([before.get(), after.get()]);
    const leftDoc = beforeQuery.empty ? null : beforeQuery.docs[0];
    const rightDoc = afterQuery.empty ? null : afterQuery.docs[0];
    const [left, right] = await Promise.all([leftDoc, rightDoc].map(imageDoc => imageDoc && firebase.addThumbnailUrls(imageDoc)));
    return { left, right }
  }

  enum Direction { Right, Left };

  const navigate = (direction: Direction) => {
    if (direction === Direction.Right && neighbors.right) {
      setPhoto(null);
      setNeighbors({ left: null, right: null });
      history.push(`${ROUTES.ALBUM(albumId)}/photo/${neighbors.right.shortId}`);
    }
    if (direction === Direction.Left && neighbors.left) {
      setPhoto(null);
      setNeighbors({ left: null, right: null });
      history.push(`${ROUTES.ALBUM(albumId)}/photo/${neighbors.left.shortId}`);
    }
  }

  function deletePhoto() {
    photoRef.delete().then(history.replace(ROUTES.ALBUM(albumId)));
  }

  useEffect(() => {
    const photoFetch = firebase.getPhoto(albumId, photoId).then(photoDoc => {
      if (!photoDoc) {
        return;
      }
      const photo = photoDoc.data();
      setPhotoRef(photoDoc.ref);
      const phone = photo.uploader;
      setPhoto(photo);
      getNeightbors(photoDoc).then(setNeighbors);
      return photoDoc;
    });
    const albumFetch = firebase.getAlbum(albumId).then(albumQuery => {
      setAlbum(albumQuery.docs[0].data());
      return albumQuery.docs[0];
    });
    Promise.all([photoFetch, albumFetch]).then(([photoDoc, albumDoc]) => {
      // Try to find user's official name.
      const uploaderPhone = photoDoc.data().uploader;
      firebase.getUserByPhone(uploaderPhone).then(user => {
        // If user's name doesn't exist then use the invitation nickname
        if (user && user.data().name) {
          setUploader(user.data().name);
        } else {
          firebase.db
            .collection("invites")
            .where("album", "==", albumDoc.ref)
            .where("recipient.number", "==", uploaderPhone)
            .get()
            .then(inviteQuery => {
              const invitiationDoc = inviteQuery.docs[0];
              setUploader(invitiationDoc.data().recipient.nickname);
            });
        }
      });
    });
  }, [match.params.photoId, match.params.albumId]);
  return (
    <Container>
      <div className={topBanner}>
        <Breadcrumb>
          <Breadcrumb.Section>
            {album ? (
              <Link to={ROUTES.ALBUM(albumId)}><Icon name="angle left" size="large" /><span style={{ verticalAlign: "text-bottom", fontWeight: 'bold', fontSize: "16px" }}>{album.title}</span></Link>
            ) : (
                <Icon loading name="asterisk" />
              )}
          </Breadcrumb.Section>
        </Breadcrumb>
        <div className={actions}>
          {photo && user && photo.uploader === user.phoneNumber ? (<Button onClick={() => { setShowDeleteDialog(true) }} negative size="mini">Delete</Button>) : null}
        </div>
      </div>
      <Segment style={{ padding: 0 }}>
        {!photo ? (
          <Placeholder>
            <Placeholder.Image style={{ minHeight: "200px" }} />
          </Placeholder>
        ) : (
            <div style={{ position: 'relative', minHeight: "200px" }}>
              <Image
                centered
                style={{ maxHeight: "-webkit-fill-available" }}
                src={photo.url}
              />
              <Icon size="large" inverted disabled={!neighbors.left} onClick={() => { navigate(Direction.Left) }} circular name="chevron left" style={{ position: 'absolute', left: '7px', top: '50%' }} />
              <Icon size="large" inverted disabled={!neighbors.right} onClick={() => { navigate(Direction.Right) }} circular name="chevron right" style={{ position: 'absolute', right: '7px', top: '50%' }} />
            </div>
          )}
        <Label style={{ backgroundColor: 'white' }}>
          {uploader ? (
            `Uploaded by ${uploader}`
          ) : (
              <Placeholder>
                <Placeholder.Line length="medium" />
              </Placeholder>
            )}
        </Label>
      </Segment>
      <Confirm
        open={showDeleteDialog}
        onCancel={() => { setShowDeleteDialog(false) }}
        content={"Are you sure you want to delete this photo?"}
        cancelButton='Never mind'
        confirmButton="Yes"
        onConfirm={() => { deletePhoto() }}
      />
      {/* <Segment.Group raised={false} horizontal>
        <Segment disabled={!neighbors.left} onClick={() => { navigate(Direction.Left) }} >
          <Icon name="angle left" size="large" />
          <Image src={neighbors.left && neighbors.left.thumbnailUrl} inline size="mini" rounded />
        </Segment>
        <Segment disabled={!neighbors.right} onClick={() => { navigate(Direction.Right) }} textAlign='right'>
          <Image src={neighbors.right && neighbors.right.thumbnailUrl} inline size="mini" rounded />
          <Icon name="angle right" size="large" />
        </Segment>
      </Segment.Group> */}
    </Container>
  );
}



export default compose<IProps, {}>(withUser, withFirebase)(PhotoViewer);;
