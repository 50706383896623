import React from "react";
import Firebase from "./firebase";

const FirebaseContext = React.createContext(null);

interface IProps {
  firebase: Firebase,
}

export const withFirebase = (Component: React.ComponentType<IProps>) => (props: object) => (
  <FirebaseContext.Consumer>
    {(firebase: Firebase) => <Component {...props} firebase={firebase} />}
  </FirebaseContext.Consumer>
);

export default FirebaseContext;
