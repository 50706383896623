import React from "react";
import { Button, Card, Container, Icon } from "semantic-ui-react";
import { User } from "./Firebase/firebase";
import { withUser } from "./Session";
import SignOutButton from "./SignOut";
import { Redirect } from "react-router";
import * as ROUTES from "../constants/routes";

interface IProps {
  user: User,
}

function Account({ user }: IProps) {
  if (!user) {
    return <Redirect to={ROUTES.HOME} />
  }
  return (
    <Container>
      <Card centered>
        <Card.Content>
          <Icon
            size="big"
            name="user circle outline"
            style={{ float: "right" }}
          />
          <Card.Header>{user.name}</Card.Header>
          <Card.Meta>{user.phoneNumber}</Card.Meta>
          <Card.Description />
        </Card.Content>
        <Card.Content extra>
          <Button negative as={SignOutButton}>
            Signout
          </Button>
        </Card.Content>
      </Card>
    </Container>
  );
}

// export default compose(
//   withAuthorization(user => !!user),
//   withUser
// )(Account);

export default withUser(Account);
