import React from "react";
import { Image } from "semantic-ui-react";
import { Link } from "react-router-dom";


export default function ImageCard({ href, src }) {
  return (
    <Link to={href}>
      <Image
        style={{ objectFit: "cover", backgroundColor: "darkgray" }}
        src={src}
        width="150px"
        height="150px"
        centered
      />
    </Link>
  );
}
