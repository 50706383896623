import React from "react";

import { User } from "./types";
const AuthUserContext = React.createContext(null);

interface IProps {
  user?: User;
}

const withUser = (Component: React.ComponentType<IProps>) => (
  props: Object
) => (
    <AuthUserContext.Consumer>
      {(user: User) => <Component {...props} user={user} />}
    </AuthUserContext.Consumer>
  );

export { withUser };

export default AuthUserContext;
