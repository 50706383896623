import React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { compose } from "recompose";

import AuthUserContext from "./context";
import Firebase, { withFirebase } from "../Firebase";
import * as ROUTES from "../../constants/routes";

type ConditionFunc = (user: any) => boolean;

interface IProps extends RouteComponentProps<any> {
  firebase: Firebase,
}

const withAuthorization = (condition: ConditionFunc) => (
  Component: React.ComponentType
) => {
  class WithAuthorization extends React.Component<IProps, object> {
    private unsubscribe: Function;
    componentDidMount() {
      this.unsubscribe = this.props.firebase.onAuthUserListener(
        authUser => {
          if (!condition(authUser)) {
            console.log('Setting state because user condition not met.')
            this.props.history.push({ pathname: ROUTES.SIGN_UP, state: { afterSignIn: this.props.history.location.pathname } });
          }
        },
        () => this.props.history.push({ pathname: ROUTES.SIGN_UP, state: { afterSignIn: this.props.history.location.pathname } })
      );
    }

    componentWillUnmount() {
      this.unsubscribe();
    }

    render() {
      return (
        <AuthUserContext.Consumer>
          {authUser =>
            condition(authUser) ? <Component {...this.props} /> : null
          }
        </AuthUserContext.Consumer>
      );
    }
  }

  return compose(
    withRouter,
    withFirebase
  )(WithAuthorization);
};

export default withAuthorization;
