import React, { useState } from "react";
import { Button, Input, Modal } from "semantic-ui-react";

export default function NewAlbumModal({ open, onCancel, onSubmit }) {
  const [albumName, setAlbumName] = useState("");
  return (
    <div>
      <Modal open={open} onClose={onCancel}>
        <Modal.Header>New Album</Modal.Header>
        <Modal.Content>
          <p>
            Enter the name of your new album. Once the album is created you can
            directly invite collaborators or share the link to your new album.
          </p>
          <Input
            autoFocus
            margin="dense"
            id="name"
            label="Album Name"
            type="text"
            onChange={e => {
              setAlbumName(e.target.value);
            }}
            fluid
          />
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={onCancel}>Cancel</Button>
          <Button
            onClick={() => {
              onSubmit(albumName);
            }}
          >
            Create
          </Button>
        </Modal.Actions>
      </Modal>
    </div>
  );
}
