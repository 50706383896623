import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import "semantic-ui-css/semantic.min.css";
import "./App.css";
import Account from "./components/Account";
import Album from "./components/Album";
import Home from "./components/Home";
import Landing from "./components/Landing";
import Navigation from "./components/Navigation";
import PhotoViewer from "./components/PhotoViewer";
import { provideAuthentication } from "./components/Session";
import SignUpPage, { SignInPage } from "./components/SignUp";
import * as ROUTES from "./constants/routes";
import About from "./components/About";



function App({ user }) {
  return (
    <React.Fragment>
      <Router>
        <Navigation />
        <Route path={ROUTES.SIGN_UP} component={SignUpPage} />
        <Route path={ROUTES.SIGN_IN} component={SignInPage} />
        <Route path={ROUTES.HOME} component={Home} />
        <Route path="/album/:albumId/photo/:photoId" component={PhotoViewer} />
        <Route exact path="/album/:shortId" component={Album} />
        <Route path={ROUTES.ACCOUNT} component={Account} />
        <Route path={ROUTES.ABOUT} component={About} />
        <Route exact path="/" component={Landing} />
      </Router>
    </React.Fragment>
  );
}

export default provideAuthentication(App);