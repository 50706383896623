import React from 'react';
import { Step, Icon, Modal, Header, Button, Image } from "semantic-ui-react";
import { ReactComponent as WeddingCrest } from './hb-wedding-crest.svg';
import crestSVG from './hb-wedding-crest.svg';


export default function WelcomeModal({ open, onDone }) {
    return (
        <Modal open={open}>
            <Modal.Header>Hannah and Henry's Big Day!</Modal.Header>
            <Modal.Content scrolling>
                {/* <WeddingCrest height={200} width={200} viewBox="210 260 150 150" /> */}
                <Image size='small' floated='right' src={crestSVG + '#svgView(viewBox(210, 260, 150, 150))'} wrapped />
                <Modal.Description>
                    <Header>You're invited!</Header>
                    <p>
                        Here's how it works. Simply choose to upload your photos directly on this website or via text.
                        If you don't receive a text (not a lot of phone service up here!) no worries, you can always share your photos using the "upload" button.
                    </p>
                </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={onDone} positive>Let's do it!</Button>
            </Modal.Actions>
        </Modal>
    )
}

function Steps() {
    return (
        <Step.Group fluid>
            <Step active>
                <Icon name='info' />
                <Step.Content>
                    <Step.Title>Welcome</Step.Title>
                    <Step.Description>Learn the basics</Step.Description>
                </Step.Content>
            </Step>
            <Step>
                <Icon name='sign-in' />
                <Step.Content>
                    <Step.Title>Join</Step.Title>
                    <Step.Description>Get set up</Step.Description>
                </Step.Content>
            </Step>
            <Step>
                <Icon name='upload' />
                <Step.Content>
                    <Step.Title>Upload</Step.Title>
                    <Step.Description>Share your photos</Step.Description>
                </Step.Content>
            </Step>
        </Step.Group>
    )
}