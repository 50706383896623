import React from "react";
import { withUser } from "./Session";
import { Redirect } from "react-router-dom";
import * as ROUTES from "../constants/routes";
import Welcome from "./Welcome";

function Landing({ user }) {
  if (user) {
    return <Redirect to={ROUTES.HOME} />;
  }
  return <Welcome />;
}

export default withUser(Landing);
