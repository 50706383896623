import React from "react";
import { Button, Container, Header, Segment } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { ABOUT } from '../constants/routes';
import { ReactComponent as Logo } from './jointly-bw.svg';

export default function () {
  return (
    <div>
      <Segment vertical textAlign="center">
        <Container className="content">
          <Logo width={200} height={200} style={{ margin: "-50px 0" }} />
          <Header as="h1">Photos in the moment.</Header>
          <p>
            Jointly makes capturing every angle of your favorite memories easy.
            Bring together every photo captured by anyone just by sending a
            text.
          </p>
          <Button as={Link} to={ABOUT} size="huge">Learn more</Button>
        </Container>
      </Segment>
    </div>
  );
}
