import React from "react";

import { Link } from "react-router-dom";
import * as ROUTES from "../constants/routes";
import { Container, Button, Segment, List, Accordion } from "semantic-ui-react";

function About({ user }) {
  return (
    <Container>
      <h1>Welcome to Jointly!</h1>
      <h3>The easiest way to consolidate your photos.</h3>
      <p>
        With Jointly it's easy to gather all the photos from your wedding,
        party, vacation, outing, or any important moment. All you have to do is
        reply to our text with the photos you want to share and Jointly does the
        rest.
      </p>
      <h3>Creating a group photo album is easy...</h3>
      <Segment.Group>
        <Segment as="h3">Getting started...</Segment>
        <Segment>
          <List ordered>
            <List.Item>Create an new album</List.Item>
            <List.Item>
              Invite your friends, coworkers, or guests by their phone number. Or
            buy a <b>pro pass</b> and get personalized website just for your special event.
          </List.Item>
            <List.Item>
              Wait for your friends to <i>text</i> their best photos and grow the
              album!
          </List.Item>
          </List>
          <Button
            as={Link}
            to={ROUTES.SIGN_UP}
            primary
          >
            Get started
      </Button>
        </Segment>
      </Segment.Group>



      <FAQ />
    </Container>
  );
}

const faqData = [{
  title: "Is Jointly free?",
  content: "Absolutely! Creating colloborative photo albums with your friends won't cost you a dime. However, we do provide premium albums for a more professional and customized experience.",
},
{
  title: "Can I use Jointly with Google Photos, Shutterfly, iCloud, etc",
  content: "Integrations with other photo storage platforms is coming soon. Until then you can download the photos individually and upload them to your libary.",
},
{
  title: "Can I use Jointly for my wedding, company retreat, birthday, etc?",
  content: "Of course! Jointly is designed to make collecting photos from large events and big groups easy. Send an email to getstarted@jointlyshare.com to make the perfect home for your event.",
}
].map((data, i) => ({ key: `faq-${i}`, ...data }));


function FAQ() {
  return (
    <Segment.Group>
      <Segment as="h3">FAQ</Segment>
      <Segment>
        <Accordion exclusive={false} panels={faqData} />
      </Segment>
    </Segment.Group>
  )
}

export default About;
