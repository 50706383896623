import React from "react";
import { Link, withRouter } from "react-router-dom";

import { AuthUserContext } from "./Session";
import SignOutButton from "./SignOut";
import * as ROUTES from "../constants/routes";
import { Button, Menu, Container, Segment } from "semantic-ui-react";
import { LocationDescriptorObject } from "history";

function NavBar({ links }) {
  return (
    <Menu style={{ border: "none", borderRadius: "0" }} borderless>
      <Container as="nav">
        <Menu.Item>
          <Link to={"/"}>
            <h2>Jointly</h2>
          </Link>
        </Menu.Item>
        {links}
      </Container>
    </Menu>
  );
}

const Navigation = () => (
  <AuthUserContext.Consumer>
    {authUser => (
      <NavBar
        links={
          authUser ? (
            <NavigationAuth authUser={authUser} />
          ) : (
              <NavigationNonAuth />
            )
        }
      />
    )}
  </AuthUserContext.Consumer>
);

const NavigationAuth = ({ authUser }) => (
  <React.Fragment>
    <Menu.Item position="right" as={Link} to={ROUTES.ACCOUNT}>
      Account
    </Menu.Item>
  </React.Fragment>
);

const NavigationNonAuth = withRouter(({ location }) => (
  <Menu.Item position="right">
    <Link to={{ pathname: ROUTES.SIGN_UP, state: { afterSignIn: location.pathname } } as LocationDescriptorObject<any>} >
      {location.pathname.includes("album/wQEUeS2E5") ? null : <Button primary>Sign Up</Button>}
    </Link>
  </Menu.Item >
));

export default Navigation;
