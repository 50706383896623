import React, { useEffect, useState } from "react";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import MaskedInput from "react-text-mask";
import { compose } from "recompose";
import { Button, Card, Container, Input, Message } from "semantic-ui-react";
import * as ROUTES from "../constants/routes";
import Firebase, { withFirebase } from "./Firebase";


const SignUpPage = () => (
  <Container>
    <SignUpForm loginOnly={false} />
  </Container>
);

const SignInPage = () => (
  <Container>
    <SignUpForm loginOnly={true} />
  </Container>
);

interface IProps extends RouteComponentProps<any> {
  firebase: Firebase;
  loginOnly: boolean;
}

function SignUpFormBase({ firebase, history, loginOnly }: IProps) {
  const [codeVerifier, setCodeVerifier] = useState(null);

  return (
    <Container>
      {!codeVerifier ? (
        <React.Fragment>
          <Card centered>
            <Card.Content>
              <Card.Header>{loginOnly ? "Sign In" : "Sign Up"}</Card.Header>
            </Card.Content>
            <Card.Content>
              <RegisterForm
                history={history}
                firebase={firebase}
                setCodeVerifier={setCodeVerifier}
                loginOnly={loginOnly}
              />
            </Card.Content>
            <Message attached='bottom' warning>
              {loginOnly ? (
                <span>
                  Don't have an account? <Link to={{ pathname: ROUTES.SIGN_UP, state: history.location.state }}>Sign up</Link>
                </span>
              ) : (
                  <span>
                    Already have an account? <Link to={{ pathname: ROUTES.SIGN_IN, state: history.location.state }}>Log in</Link>
                  </span>
                )}
            </Message>
          </Card>
        </React.Fragment>
      ) : (
          <ConfirmCodeForm codeVerifier={codeVerifier} />
        )}
    </Container>
  );
}

function ConfirmCodeForm({ codeVerifier }) {
  const [code, setCode] = useState("");

  console.log("Rendering confirm form", codeVerifier);

  return (
    <React.Fragment>
      <Card>
        <Card.Content>
          <Card.Header>Confirm phone number</Card.Header>
        </Card.Content>
        <Card.Content>
          <form
            onSubmit={e => {
              e.preventDefault();
              console.log("Submitting code", code, codeVerifier);
              codeVerifier.confirm(code);
            }}
          >
            <p>A confirmation code has been sent to your phone. Please enter the code below.</p>
            <Input
              name="code"
              value={code}
              style={{ marginBottom: "10px" }}
              onChange={e => {
                setCode(e.target.value);
              }}
              type="text"
              placeholder="Confirmation Code"
            />
            <Button type="submit">Confirm</Button>
          </form>
        </Card.Content>
      </Card>
    </React.Fragment>
  );
}

declare global {
  interface Window { recaptchaVerifier: any; }
}

function RegisterForm({ firebase, setCodeVerifier, history, loginOnly }) {
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {
    window.recaptchaVerifier = firebase.captchaVerifier("sign-in-button");
  }, []);

  const onSubmit = event => {
    event.preventDefault();
    const formatPhone = `${phone.split(/[ -]/).join("")}`;
    firebase
      .signUpWithPhone(formatPhone, window.recaptchaVerifier)
      .then(codeVerifier => {
        setCodeVerifier({
          confirm: code => {
            return codeVerifier
              .confirm(code)
              .then(result => {
                if (loginOnly) {
                  return;
                }
                return firebase.user(result.user.uid).set({
                  name,
                  phoneNumber: formatPhone
                });
              })
              .then(() => {
                const afterSignIn = history.location.state && history.location.state.afterSignIn;
                if (afterSignIn) {
                  return history.replace(afterSignIn);
                }
                history.push(ROUTES.HOME);
              });
          }
        });
      })
      .catch(error => {
        console.log(error);

        //setError(error);
      });
  };

  const isInvalid = !!error || (!loginOnly && !name) || !phone;

  return (
    <form onSubmit={onSubmit}>
      {loginOnly ? null : (
        <Input
          required
          fluid
          name="name"
          value={name}
          onChange={e => setName(e.target.value)}
          type="text"
          label="Full Name"
        />
      )}
      <Input
        required
        fluid
        style={{ marginTop: "8px" }}
        name="phone"
        label="Phone Number"
        input={
          <MaskedInput
            mask={[
              "+",
              "1",
              " ",
              /\d/,
              /\d/,
              /\d/,
              "-",
              /\d/,
              /\d/,
              /\d/,
              "-",
              /\d/,
              /\d/,
              /\d/,
              /\d/
            ]}
            type="tel"
            pattern="\+1 \d{3}-[\d]{3}-[\d]{4}"
            guide={false}
            placeholder="555-555-5555"
          />
        }
        value={phone}
        onChange={e => setPhone(e.target.value)}
      />
      <Button
        style={{ marginTop: "8px" }}
        id="sign-in-button"
        disabled={isInvalid}
        type="submit"
        fluid
      >
        {loginOnly ? "Sign In" : "Sign Up"}
      </Button>
    </form>
  );
}

const SignUpLink = () => (
  <p>
    Don't have an account? <Link to={ROUTES.SIGN_UP}>Sign Up</Link>
  </p>
);

interface ISignUpProps {
  loginOnly: boolean;
}

const SignUpForm = compose<IProps, ISignUpProps>(
  withRouter,
  withFirebase
)(SignUpFormBase);

export default SignUpPage;

export { SignUpForm, SignUpLink, SignInPage };

