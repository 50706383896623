import React, { useEffect, useState, useRef } from "react";
import debounce from 'lodash/debounce';
import { Grid } from "semantic-ui-react";

interface IPropTypes {
    children: React.Component,
    isDone: boolean,
    loadMore: Promise<any>,
    loadingView: React.Component,
    errorView: React.Component,
}

const scrollReactor = callback => () => {

    const pxFromBottom = document.body.offsetHeight - (window.innerHeight + window.scrollY);
    if (pxFromBottom < 25) {
        callback();
    }
}

export default function InfiniteScroll({ children, isDone, loadMore, loadingView, errorView }) {
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(false);
    const callbackState = useRef({ isLoading, isDone });

    useEffect(() => {
        callbackState.current = {
            isLoading,
            isDone
        }
    })

    const callback = () => {
        const { isDone, isLoading } = callbackState.current;
        if (!isDone && !isLoading) {
            console.log('triggering load');
            triggerLoad();
        }
        if (isDone) {
            console.log('all done.');
            detachListeners();
        }
    }


    const onWindowScroll = debounce(scrollReactor(callback), 300);

    const detachListeners = () => {
        window.removeEventListener('scroll', onWindowScroll);
    }

    const triggerLoad = () => {
        console.log('Setting loading true.');
        setIsLoading(true);
        loadMore().then(() => {
            console.log('Setting loading false.');
            setIsLoading(false);
        }).catch(err => {
            setError(true);
        });
    }

    useEffect(() => {
        window.addEventListener('scroll', onWindowScroll);
        triggerLoad();
        return () => {
            detachListeners();
        }
    }, []);
    return (
        <React.Fragment>
            {children}
            <Grid.Row style={{ height: "150px" }}>
                {isLoading ? loadingView : null}
                {error ? errorView : null}
            </Grid.Row>
        </React.Fragment>
    );
}